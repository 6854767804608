<ng-container *transloco="let t; read: 'navigation'">
  <section
    class="navigation"
    [class.is-mobile]="isMobile"
    [class.is-small-screen]="screenSize?.small"
  >
    <mat-toolbar
      color="primary"
      role="heading"
      class="toolbar mat-elevation-z2"
    >
      <button
        *ngIf="activeOrganization && user && screenSize?.medium"
        mat-icon-button
        type="button"
        aria-label="Toggle sidenav"
        (click)="sidenav.toggle()"
      >
        <mat-icon class="burger-icon" aria-label="Side nav toggle icon"
          >menu</mat-icon
        >
      </button>
      <img
        *ngIf="!screenSize?.medium"
        fxLayout
        fxLayoutAlign="start center"
        routerLink="/"
        style="cursor: pointer"
        alt="arrivage-logo"
        [height]="sidenavFixedTopGap"
        [src]="arrivageLogoURL$ | async"
      />
      <div fxFlex fxLayout fxLayoutAlign="flex-end">
        <app-toolbar
          [activeOrganization]="activeOrganization"
          [settings]="settings"
          [authUser]="authUser"
          [user]="user"
          [notifications]="notifications"
          (notificationRead)="onNotificationRead($event)"
        ></app-toolbar>
      </div>
    </mat-toolbar>

    <mat-toolbar
      *ngIf="
        activeOrganization?.isSeller &&
        isStandardAccount &&
        creditAccount?.balance?.amount <= 0
      "
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap.gt-sm="2rem"
      class="credit-banner"
      [class.is-mobile]="screenSize?.small"
    >
      <div fxFlex [class.small]="screenSize?.small">
        <span *ngIf="!screenSize?.medium" class="text-color-error">
          {{ t('credit-account-warning') }}
        </span>
        <a
          href="https://www.arrivage.com/paiement"
          target="blank"
          class="text-color-error bold"
        >
          {{ t('add-credit-or-upgrade') }}
        </a>
      </div>
    </mat-toolbar>

    <ng-container>
      <mat-sidenav-container
        class="sidenav-container"
        [class.credit-banner-opened]="
          activeOrganization?.isSeller &&
          isStandardAccount &&
          creditAccount?.balance?.amount <= 0
        "
        [autosize]="true"
      >
        <mat-sidenav
          *ngIf="activeOrganization && user && authUser"
          class="sidenav"
          fixedInViewport="true"
          [@expandAnimation]="isExpanded ? 'expanded' : 'collapsed'"
          [fixedTopGap]="sidenavFixedTopGap"
          [attr.role]="screenSize?.medium ? 'dialog' : 'navigation'"
          [mode]="screenSize?.medium ? 'over' : 'side'"
          [opened]="!screenSize?.medium"
        >
          <app-sidenav
            [authUser]="authUser"
            [user]="user"
            [activeOrganization]="activeOrganization"
            [allOrganizations]="allOrganizations"
            [creditAccount]="creditAccount"
            [premiumAccount]="premiumAccount"
            [customerPurchaseOrderMetrics]="customerPurchaseOrderMetrics"
            [vendorPurchaseOrderMetrics]="vendorPurchaseOrderMetrics"
            [offerByPriceList]="offerByPriceList"
            [priceLists]="priceLists"
            [deliveries]="deliveries"
            [conversations]="conversations"
            [customersAccessByPriceList]="customersAccessByPriceList"
            [deliveryByRelationship]="deliveryByRelationship"
            [isMediumOrSmaller]="screenSize?.medium"
            (expandedSideNav)="isExpanded = $event"
            (closeSidenav)="closingSidenav()"
          ></app-sidenav>
        </mat-sidenav>

        <mat-sidenav-content
          cdkScrollable
          role="main"
          fxLayout="column"
          [ngClass]="{ 'sidenav-collapsed': !isExpanded }"
        >
          <div
            fxFlex
            [ngClass]="{ 'bottom-padding': !isPageWithoutBottomPadding }"
          >
            <ng-content></ng-content>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </ng-container>
  </section>
</ng-container>
