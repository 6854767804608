<ng-container *transloco="let t">
  <section
    fxLayout="column"
    fxLayoutGap="1rem"
    [class.header-margin]="!noMarginBottom"
  >
    <section class="header-section">
      <section
        fxLayout="row wrap"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start start"
        fxLayoutGap="3rem"
        fxLayoutGap.gt-sm="5rem"
      >
        <button
          *ngIf="allowBack"
          mat-mini-fab
          color="accent"
          class="mat-elevation-z0 button"
          (click)="back()"
        >
          <mat-icon class="icon-ms" [class.icon-s]="isExtraSmallScreen$ | async"
            >arrow_back</mat-icon
          >
        </button>
        <section fxFlex fxLayout="column">
          <mat-card-title
            fxLayout="row"
            fxLayoutGap="1rem"
            fxLayoutAlign="start center"
            class="text-xxl"
          >
            <img *ngIf="logo" matCardAvatar [src]="logo" />
            <span>{{ title }}</span>
            <button
              *ngIf="helpBox"
              mat-icon-button
              type="button"
              [customTooltip]="t('header.help-button')"
              (click)="onShowHelpBox()"
            >
              <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            </button>
            <ng-content select="[id=header-trailing]"></ng-content>
          </mat-card-title>

          <!-- Subtitle Section -->
          <article
            class="text-m text-light"
            [@openCloseAnimation]="openCloseState"
          >
            <ng-content select="[id=subtitle]"></ng-content>
          </article>
        </section>

        <!-- Action Buttons Section -->
        <section class="action-buttons">
          <ng-content select="[id=action-buttons]"></ng-content>
        </section>
      </section>
    </section>
  </section>
</ng-container>
