export enum LoadingFeedback {
  LOADING = 'loading',
  ON_IMPORT = 'on_import',
  ON_CREATE_PRODUCT = 'on_create_product',
  ON_DUPLICATE_PRODUCT = 'on_duplicate_product',
  ON_DUPLICATE_PRODUCTS = 'on_duplicate_products',
  ON_DUPLICATE_FORMAT = 'on_duplicate_format',
  ON_DUPLICATE_FORMATS = 'on_duplicate_formats',
  ON_CREATE_FORMAT_AND_INVENTORY_ITEM = 'on_create_format_and_inventory_item',
  ON_DELETE_FORMAT_AND_INVENTORY_ITEM = 'on_delete_format_and_inventory_item',
  ON_DELETE_FORMATS_AND_INVENTORY_ITEMS = 'on_delete_formats_and_inventory_items',
  ON_DELETE_PRODUCT = 'on_delete_product',
  ON_DELETE_PRODUCTS = 'on_delete_products',
  ON_CREATE_INVOICE = 'on_create_invoice',
  ON_GENERATE_INVOICE_PDF = 'on_generate_invoice_pdf',
  ON_MARKING_INVOICE_PAID = 'on_marking_invoice_paid',
  ON_MARKING_INVOICES_PAID = 'on_marking_invoices_paid',
  ON_UPDATE_INVOICE = 'on_update_invoice',
  ON_SEND_INVOICE = 'on_send_invoice',
  ON_SEND_INVOICES = 'on_send_invoices',
  ON_PUBLISH_INVOICE = 'on_publish_invoice',
  ON_DELETE_INVOICE = 'on_delete_invoice',
  ON_CREATE_PO = 'on_create_po',
  ON_UPDATE_PO = 'on_update_po',
  ON_SEND_PO = 'on_send_po',
  ON_SEND_PICK_SPLIT_LIST = 'on_send_pick_split_list',
  ON_SEND_PRODUCER_SALES_REPORT = 'on_send_producer_sales_report',
  ON_CONNECTING_ACCOUNTS = 'on_connecting_accounts',
  ON_CREATE_PRICE_LIST = 'on_create_price_list',
  ON_DELETE_PRICE_LIST = 'on_delete_price_list',
  ON_UPDATE_PRICE_LIST = 'on_update_price_list',
  ON_DUPLICATE_PRICE_LIST = 'on_duplicate_price_list',
  ON_UPDATING_PUBLIC_LISTS = 'on_updating_public_lists',
  ON_SHARING_OFFER = 'on_sharing_offer',
  ON_SHARING_OFFER_TEST = 'on_sharing_offer_test',
  ON_CREATE_SCHEDULED_OFFER = 'on_create_scheduled_offer',
  ON_DELETE_SCHEDULED_OFFER = 'on_delete_scheduled_offer',
  ON_UPDATE_SCHEDULED_OFFER = 'on_update_scheduled_offer',
  ON_ACCEPT_CONTACT_REQUEST = 'on_accept_contact_request',
  ON_DECLINE_CONTACT_REQUEST = 'on_decline_contact_request',
  ON_CREATE_DELIVERY = 'on_create_delivery',
  ON_DELETE_DELIVERY = 'on_delete_delivery',
  ON_DELETE_PICKUP = 'on_delete_pickup',
  ON_VERIFY_PURCHASE_ORDER = 'on_verify_purchase_order',
  ON_CREDIT_ADJUSTMENT = 'on_credit_adjustment',
  ON_SAVE = 'on_save',
  ON_GENERATE_ALL_DELIVERY_FORMS_PDF = 'on_generate_all_delivery_forms_pdf',
  ON_GENERATE_PICK_LIST_PDF = 'on_generate_pick_list_pdf',
  ON_GENERATE_DELIVERY_REPORT_PDF = 'on_generate_delivery_report_pdf',
  ON_GENERATE_ALL_PURCHASE_ORDERS_PDF = 'on_generate_all_purchase_orders_pdf',
  ON_GENERATE_PACK_LIST_PDF = 'on_generate_pack_list_pdf',
  ON_CREATE_PRODUCER = 'on_create_producer',
  ON_UPDATE_PRODUCER = 'on_update_producer',
  ON_DELETE_PRODUCER = 'on_delete_producer',
  ON_LINK_PRODUCER_TO_EXISTING_ORGANIZATION = 'on_link_producer_to_existing_organization',
  ON_LOAD_PUBLIC_OFFER_PAGE = 'on_load_public_offer_page',
  SENDING = 'sending',
}
