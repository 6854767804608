<ng-container *transloco="let t; scope: 'products'; read: 'products'">
  <ng-container *transloco="let g">
    <mat-card-content class="card-content">
      <!--MARK:  PRODUCT'S PRESENTATION SECTION
      -->
      <section class="full-width">
        <!-- CATEGORY AND DEPARTMENT -->
        @if (product.department) {
          <div class="department" [class.blur-filter]="showImage">
            <app-breadcrumb
              class="text-s bold uppercase text-light"
              [parent]="g('enums.department.' + product.department)"
              [child]="product.category | translate"
            >
            </app-breadcrumb>
          </div>
        } @else {
          <div
            class="text-s bold uppercase text-light"
            [class.blur-filter]="showImage"
          >
            <app-label [label]="product.category | translate"></app-label>
          </div>
        }

        <section
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="2rem"
        >
          <!-- NAME AND LABELS -->
          <div
            fxLayout="column"
            fxLayoutGap="1rem"
            fxLayoutAlign="start"
            [class.blur-filter]="showImage"
          >
            <span class="text-l">
              {{ product.name | translate }}

              @if (currentOfferItem?.format?.description) {
                - {{ currentOfferItem.format | displayFormat | uppercaseFirst }}
                &nbsp;
                @if (
                  currentOfferItem.format.grade || currentOfferItem.format.sized
                ) {
                  [{{
                    t('inventory-line.grade', {
                      grade: currentOfferItem.format.grade
                    })
                  }}{{
                    currentOfferItem.format.grade &&
                    currentOfferItem.format.sized
                      ? ', '
                      : ''
                  }}{{
                    t('inventory-line.sized', {
                      sized: currentOfferItem.format.sized
                    })
                  }}]
                }
              }
            </span>

            <div fxLayout="row" fxLayoutGap="8px">
              @if (product.isCertifiedOrganic) {
                <app-label-is-organic />
              }
              @if (
                product.localIngredients ===
                LOCAL_INGREDIENT.IS_HUNDRED_PERCENT_LOCAL
              ) {
                <app-label-is-hundred-percent-local />
              }
              @if (
                product.localIngredients ===
                LOCAL_INGREDIENT.DO_NOT_CONTAIN_LOCAL_INGREDIENTS
              ) {
                <app-label-not-local />
              }
            </div>

            <!-- PRODUCER NAME -->
            @if (product.resellingFor) {
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="0.5rem"
                class="text-light"
              >
                <mat-icon>groups</mat-icon>
                <span>{{ product.resellingFor }}</span>
              </div>
            }
          </div>

          <!-- PRICE AND STOCK -->
          @if (showPriceAndStock && (currentFormatId || orderItem)) {
            <div
              fxLayout="column"
              fxLayoutGap="16px"
              fxLayoutAlign="center end"
              [class.blur-filter]="showImage"
            >
              <!-- PRICE -->
              <app-format-price
                [price]="orderItem ? orderItem.price : currentOfferItem.price"
                [regularPrice]="
                  orderItem
                    ? orderItem.regularPrice
                    : currentOfferItem.regularPrice
                "
                [format]="
                  orderItem ? orderItem.format : currentOfferItem.format
                "
                [size]="'large'"
              ></app-format-price>

              <!-- PRICE BY UNIT-->
              <div
                class="bold"
                [class.text-color-error]="
                  orderItem
                    ? orderItem.regularPrice
                    : currentOfferItem.regularPrice
                "
              >
                <span>{{
                  (orderItem ? orderItem.price : currentOfferItem.price)
                    | displayUnitPrice
                      : (orderItem
                          ? orderItem.format.quantity
                          : currentOfferItem.format.quantity)
                    | displayMoneyAmount
                    | translocoCurrency
                      : 'symbol'
                      : {}
                      : (orderItem
                          ? orderItem.price.currency
                          : currentOfferItem.price.currency)
                }}</span>
                @if (
                  orderItem?.format?.sellingUnit ||
                  currentOfferItem?.format?.sellingUnit
                ) {
                  <span
                    >/{{
                      (orderItem
                        ? orderItem.format.sellingUnit
                        : currentOfferItem.format.sellingUnit
                      ) | displayAsPriceUnit
                    }}</span
                  >
                }
              </div>

              <!-- STOCK -->
              @if (currentOfferItem?.quantity || orderItem?.requestedQuantity) {
                <div fxLayout="row wrap" fxLayoutGap="2px" fxLayoutAlign="end">
                  <span
                    >{{
                      orderItem
                        ? t('product-info-dialog.quantity')
                        : t('product-info-dialog.stock')
                    }}
                    :</span
                  >
                  <span class="bold">
                    {{
                      orderItem
                        ? orderItem.requestedQuantity
                        : (currentOfferItem.quantity || 0 | number)
                    }}</span
                  >
                </div>
              }
            </div>
          }

          <!-- IMAGE -->
          <img
            class="img-zoomable no-blur-filter"
            [ngClass]="
              isExtraSmallScreen
                ? 'img-product img-product--small'
                : 'image-product-m'
            "
            [src]="
              currentOfferItem?.format?.photoUrl ||
              product?.mainPhotoUrl ||
              DEFAULT_PRODUCT_PICTURE(product.department)
            "
            (mouseover)="showImage = true"
            (mouseout)="showImage = false"
            (blur)="showImage = false"
            (focus)="showImage = true"
          />
        </section>
      </section>

      <mat-divider
        [ngClass]="{
          'first-divider': !isExtraSmallScreen,
          'blur-filter': showImage
        }"
      ></mat-divider>

      <!--MARK: ADDITIONAL INFORMATION
      -->
      <section [class.blur-filter]="showImage">
        <!-- DESCRIPTION -->
        @if (product.shortDescription) {
          <!-- to avoid extra whitespace -->
          <!-- prettier-ignore -->
          <div class="text-m no-bold description keep-text-formatting">{{
            product.shortDescription | translate
          }}</div>
        }

        <section fxLayout="column" fxLayoutGap="0.5rem">
          <!-- CONSERVATION -->
          @if (product.conservation) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m"> {{ t('form.conservation') }} : </span>
              <span class="text-m no-bold information-details">
                {{
                  g('enums.conservation', {
                    conservation:
                      product.conservation || CONSERVATION.NON_REFRIGERATED
                  })
                }}
              </span>
            </div>
          }

          <!-- CONSERVATION DESCRIPTION -->
          @if (product.conservationDescription) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m">
                {{ t('form.conservation-tips') }} :
              </span>
              <span class="text-m no-bold information-details">
                {{ product.conservationDescription | translate }}
              </span>
            </div>
          }

          <!-- AVAILABILITY -->
          @if (product.availability) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m">
                {{ t('form.availability-type') }} :
              </span>
              <div class="text-m no-bold information-details">
                {{
                  t('form.availability-options', {
                    option: product.availability.type
                  })
                }}
                @if (product.availability?.availabilityRange?.range[0]) {
                  <span>
                    ({{ t('product.from') }}
                    {{
                      g(
                        'enums.mid-month.' +
                          product.availability.availabilityRange.range[0].from
                      )
                    }}
                    {{ t('product.to') }}
                    {{
                      g(
                        'enums.mid-month.' +
                          product.availability.availabilityRange.range[0].to
                      )
                    }})
                  </span>
                }
              </div>
            </div>
          }

          <!-- OFFER CONTINUITY -->
          @if (product.offerContinuity) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m"
                >{{ t('form.offer-continuity') }} :
              </span>
              <span class="text-m no-bold information-details">
                {{ g('enums.offer-continuity.' + product.offerContinuity) }}
              </span>
            </div>
          }

          <!-- QUEBEC CERTIFICATION -->
          @if (product.alimentsDuQuebecCertification) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m">
                {{ t('form.aliments-du-quebec') }} :
              </span>
              <span class="text-m no-bold information-details">
                {{
                  g(
                    'enums.certification-aliments-du-quebec.' +
                      product.alimentsDuQuebecCertification
                  )
                }}
              </span>
            </div>
          }

          <!-- USAGES -->
          @if (product.usages?.length > 0) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m"> {{ t('form.usages') }} : </span>
              <div
                fxLayout="row wrap"
                fxLayoutGap="2px"
                class="text-m no-bold information-details"
              >
                <span *ngFor="let value of product.usages; let isLast = last">
                  {{ g('enums.usage.' + value) }}{{ isLast ? '' : ' - ' }}
                </span>
              </div>
            </div>
          }

          <!-- ALLERGENS -->
          @if (product.allergens) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m"> {{ t('form.allergens') }} : </span>
              <span class="text-m no-bold information-details">
                {{ product.allergens | translate }}
              </span>
            </div>
          }

          <!-- PACKAGING INFOS-->
          @if (currentOfferItem?.format?.packagingInfo) {
            <div fxLayout="row" fxLayoutAlign="start baseline">
              <span class="label text-m">
                {{ t('format-form.packaging-info') }} :&nbsp;
              </span>
              <span class="text-m no-bold information-details">
                {{ currentOfferItem.format.packagingInfo | translate }}
              </span>
            </div>
          }
        </section>
      </section>

      <!--MARK: FORMATS SECTION
      -->
      @if (filteredOfferItems?.length > 0 && offerItems?.length > 0) {
        <section
          fxLayout="column"
          fxLayoutGap="16px"
          [class.blur-filter]="showImage"
        >
          <mat-divider class="thin-outline-yellow"></mat-divider>
          <div class="text-l">
            {{
              currentFormatId
                ? t('product-info-dialog.other-formats', {
                    offerItems: filteredOfferItems.length
                  })
                : t('product-info-dialog.all-formats', {
                    offerItems: offerItems.length
                  })
            }}
          </div>
          <app-format-list
            [formats]="currentFormatId ? filteredOfferItems : offerItems"
            [product]="product"
            [showPriceAndStock]="showPriceAndStock"
          ></app-format-list>
        </section>
      }

      <!--MARK: ADDITIONAL FILES SECTION
      -->
      @if (isFilesUrlAvailable) {
        <section
          fxLayout="column"
          fxLayoutGap="16px"
          [class.blur-filter]="showImage"
        >
          <mat-divider class="thin-outline-yellow"></mat-divider>
          <div class="text-l">{{ t('form.additional-documents') }}</div>
          <app-upload-files
            [displayedFiles]="product.filesUrl"
            [fileTypeAccepted]="['pdf', 'image']"
            [editable]="false"
          ></app-upload-files>
        </section>
      }

      @if (showImage) {
        <img
          class="img-product-xl img-absolute"
          [src]="
            product?.mainPhotoUrl || DEFAULT_PRODUCT_PICTURE(product.department)
          "
        />
      }
    </mat-card-content>
  </ng-container>
</ng-container>
