import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';

import { WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { ScheduledOffer } from '@arrivage/model/dist/src/model/scheduled-offer';

@Injectable({
  providedIn: 'root',
})
export class ScheduledOfferService
  extends BaseService<ScheduledOffer>
  implements OrganizationEntityService<ScheduledOffer>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;

  static readonly SCHEDULED_OFFER_COLLECTION =
    CollectionNames.SCHEDULED_OFFERS_COLLECTION;

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<ScheduledOffer & WithId> {
    return this._get(this.pathSpec(organizationId), recordId);
  }

  create(
    organizationId: string,
    scheduledOffer: ScheduledOffer
  ): Promise<string> {
    return this._create(this.pathSpec(organizationId), scheduledOffer);
  }

  set(
    organizationId: string,
    recordId: string,
    record: ScheduledOffer
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    scheduledOfferId: string,
    scheduledOffer: ScheduledOffer
  ): Promise<void> {
    return this._update(
      this.pathSpec(organizationId),
      scheduledOfferId,
      scheduledOffer
    );
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<ScheduledOffer>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(ScheduledOffer & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, scheduledOfferId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), scheduledOfferId);
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: ScheduledOfferService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: ScheduledOfferService.SCHEDULED_OFFER_COLLECTION },
    ];
  }
}
