import { from, of } from 'rxjs';

import { Injectable } from '@angular/core';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { createBasePurchaseOrderEffects } from '@arrivage-purchase-orders/common/store/base-purchase-orders.effects';
import { VendorPurchaseOrderService } from '@arrivage-purchase-orders/vendor/services/vendor-purchase-order.service';
import { PictureService } from '@arrivage-services/picture.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { State } from '@arrivage-store/state';
import { ALL_PURCHASE_ORDER_STATUSES } from '@arrivage/model/dist/src/model';

import { VendorPurchaseOrderFacade } from '../api/vendor-purchase-orders.facade';
import {
  updateWithExternalFile,
  VendorPurchaseOrdersActions,
} from './vendor-purchase-orders.actions';
import { VendorPurchaseOrdersSelectors } from './vendor-purchase-orders.selectors';

@Injectable()
export class VendorPurchaseOrdersEffects {
  query$;
  displayQueryFailure$;

  add$;
  update$;
  remove$;

  getActiveItem$;
  displayGetActiveItemFailure$;

  queryByDateRange$;
  queryByDateRangeGuard$;
  displayQueryByDateRangeFailure$;
  querySubPurchaseOrders$;
  querySubPurchaseOrdersByDateRangeGuard$;
  querySubPurchaseOrdersByDateRange$;
  queryAllPurchaseOrdersByDateRangeGuard$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: VendorPurchaseOrderService,
    private snackbarService: SnackbarService,
    private pictureService: PictureService,
    private vendorPurchaseOrderFacade: VendorPurchaseOrderFacade
  ) {
    ({
      query: this.query$,
      displayQueryFailure: this.displayQueryFailure$,

      add: this.add$,
      update: this.update$,
      remove: this.remove$,

      getActiveItem: this.getActiveItem$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,

      queryByDateRange: this.queryByDateRange$,
      queryByDateRangeGuard: this.queryByDateRangeGuard$,
      displayQueryByDateRangeFailure: this.displayQueryByDateRangeFailure$,
      querySubPurchaseOrdersByDateRangeGuard:
        this.querySubPurchaseOrdersByDateRangeGuard$,
      querySubPurchaseOrdersByDateRange:
        this.querySubPurchaseOrdersByDateRange$,
      queryAllPurchaseOrdersByDateRangeGuard:
        this.queryAllPurchaseOrdersByDateRangeGuard$,
    } = createBasePurchaseOrderEffects(
      this.actions$,
      this.store,
      VendorPurchaseOrdersActions,
      VendorPurchaseOrdersSelectors,
      this.service,
      this.snackbarService,
      ALL_PURCHASE_ORDER_STATUSES
    ));
  }

  updateWithExternalFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWithExternalFile),
      switchMap((update) => {
        if (
          update.record.newExternalDeliveryNote ||
          update.record.newExternalInvoice
        ) {
          const path = update.record.newExternalDeliveryNote
            ? '/externalDeliveryNote'
            : '/externalInvoice';
          const file =
            update.record.newExternalDeliveryNote ||
            update.record.newExternalInvoice;

          return from(
            this.pictureService
              .uploadPicture(
                file,
                'purchaseOrders/' + update.record.purchaseOrder.id + path
              )
              .then(async (url) => {
                const organizationId =
                  update.record.purchaseOrder.vendor.organizationId;
                const invoiceId = update.record.purchaseOrder.invoiceId;

                // Having an invoiceId and externalInvoiceUrl at the same time
                // is not wanted in a purchase order
                const newInvoiceId =
                  organizationId &&
                  invoiceId &&
                  update.record.newExternalInvoice
                    ? null
                    : invoiceId;

                const purchaseOrder =
                  update.record.newExternalDeliveryNote !== undefined
                    ? {
                        ...update.record.purchaseOrder,
                        externalDeliveryNoteUrl: url,
                        invoiceId: newInvoiceId,
                      }
                    : {
                        ...update.record.purchaseOrder,
                        externalInvoiceUrl: url,
                        invoiceId: newInvoiceId,
                      };
                return this.vendorPurchaseOrderFacade.updateItem(purchaseOrder);
              })
          ).pipe(
            map(() => {
              update.confirmation.resolve('');
              return VendorPurchaseOrdersActions.updateSuccess({
                id: update.record.purchaseOrder.id,
              });
            }),
            catchError((error) => {
              update.confirmation.reject(error);
              return of(
                VendorPurchaseOrdersActions.updateFailure({
                  record: update.record.purchaseOrder,
                  errorMessage: error,
                })
              );
            })
          );
        } else {
          const purchaseOrder =
            update.record.newExternalDeliveryNote === null
              ? {
                  ...update.record.purchaseOrder,
                  externalDeliveryNoteUrl: null,
                }
              : {
                  ...update.record.purchaseOrder,
                  externalInvoiceUrl: null,
                };
          return from(
            this.vendorPurchaseOrderFacade.updateItem(purchaseOrder)
          ).pipe(
            map(() => {
              update.confirmation.resolve('');
              return VendorPurchaseOrdersActions.updateSuccess({
                id: update.record.purchaseOrder.id,
              });
            }),
            catchError((error) => {
              update.confirmation.reject(error);
              return of(
                VendorPurchaseOrdersActions.updateFailure({
                  record: update.record.purchaseOrder,
                  errorMessage: error,
                })
              );
            })
          );
        }
      })
    )
  );
}
