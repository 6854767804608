import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer } from '@ngrx/store';

import {
  createEntityReducer,
  createInitialState,
} from '@arrivage-store/generators';
import { WithId } from '@arrivage/model/dist/src/model';
import { ScheduledOffer } from '@arrivage/model/dist/src/model/scheduled-offer';
import { ScheduledOfferState } from './scheduled-offer.state';

import * as ScheduledOfferAction from './scheduled-offer.actions';

export const adapter: EntityAdapter<ScheduledOffer & WithId> =
  createEntityAdapter<ScheduledOffer & WithId>();

export const initialState: ScheduledOfferState = createInitialState(adapter);

const scheduledOfferReducer = createReducer(
  initialState,
  ...createEntityReducer(ScheduledOfferAction, adapter)
);

export function reducer(
  state: ScheduledOfferState | undefined,
  action: Action
) {
  return scheduledOfferReducer(state, action);
}
