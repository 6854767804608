<span
  #customTextElement
  [customTooltip]="
    customTooltip || text + (secondaryText ? ' ' + secondaryText : '')
  "
  class="custom-text"
  [tooltipDisabled]="tooltipDisabled"
  [tooltipPosition]="tooltipPosition"
  [style.-webkit-line-clamp]="nbLine"
  [class.cursor-style]="isOverflowing()"
  [ngClass]="{
    bold: bold,
    'text-italic': italic
  }"
>
  <span [class]="additionalClasses">{{ text }}</span>
  <span [class]="secondaryClasses"> {{ secondaryText }}</span>
</span>
