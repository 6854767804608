import { Component, ElementRef, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { PictureService } from '@arrivage-services/picture.service';

export type ArrivageLabelType =
  | 'lightBlueColor'
  | 'lightYellowColor'
  | 'lighterYellowColor'
  | 'lightGreen'
  | 'organic'
  | 'grey'
  | 'light-grey'
  | 'accent'
  | 'warn'
  | 'primary'
  | 'hub'
  | 'imported-item'
  | 'new-feature'
  | 'local';
export interface Avatar {
  pictureUrl: string;
}

export type ArrivageLabelSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  readonly DEFAULT_PHOTO_URL = PictureService.DEFAULT_PHOTO_URL;

  constructor(private elementRef: ElementRef) {}

  @Input()
  type: ArrivageLabelType;

  @Input()
  color: ThemePalette;

  @Input()
  label: string;

  @Input()
  avatar: Avatar;

  @Input()
  icon: string;

  @Input()
  statusTag: boolean = false;

  @Input() small: boolean = false;

  @Input() large: boolean = false;

  @Input() twoLinesLabel: boolean = false;

  @Input() bolder: boolean = false;

  get element(): ElementRef {
    return this.elementRef;
  }
}
