import { FullCalendarModule } from '@fullcalendar/angular';
import * as Sentry from '@sentry/angular-ivy';
import { SHARE_BUTTONS_CONFIG } from 'ngx-sharebuttons';

import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  ScreenTrackingService,
  UserTrackingService,
  getAnalytics,
  provideAnalytics,
  setAnalyticsCollectionEnabled,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  APP_NAME,
  APP_VERSION,
  DEBUG_MODE,
} from '@angular/fire/compat/analytics';
import { SETTINGS } from '@angular/fire/compat/firestore';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AdminComponentsModule } from '@arrivage-admin/components/admin-components.module';
import { AdminStoreModule } from '@arrivage-admin/store/admin.store.module';
import { AddressFormModule } from '@arrivage-components/address-form/address-form.module';
import { DialogConfirmationModule } from '@arrivage-components/dialog-confirmation/dialog-confirmation.module';
import { FilteredDateRangePickerDialogModule } from '@arrivage-components/filtered-date-range-picker-dialog/filtered-date-range-picker-dialog.module';
import { LoadingDialogModule } from '@arrivage-components/loading-dialog/loading-dialog.module';
import { OrganizationMiniModule } from '@arrivage-components/organization-mini/organization-mini.module';
import {
  AppShareButtonsModule,
  customShareButtonsConfig,
} from '@arrivage-components/share-buttons/share-buttons.module';
import { UnsavedChangesDialogModule } from '@arrivage-components/unsaved-changes-dialog/unsaved-changes-dialog.module';
import { UploadPictureModule } from '@arrivage-components/upload-picture/upload-picture.module';
import { MatDateFnsModule } from '@arrivage-date-adapter/date-adapter.module';
import { PickupStoreModule } from '@arrivage-distribution/vendor/store/pickup.store.module';
import { environment } from '@arrivage-environments/environment';
import { VisibleOffersStoreModule } from '@arrivage-offers/common/store/visible-offers-store.module';
import { OrganizationSetupStoreModule } from '@arrivage-organization-setup/store/organization-setup-store.module';
import { DisplayMoneyAmountPipeModule } from '@arrivage-pipes/display-money-amount-pipe/display-money-amount-pipe.module';
import { UppercaseFirstPipeModule } from '@arrivage-pipes/uppercase-first-pipe/uppercase-first-pipe.module';
import { PremiumAccountStoreModule } from '@arrivage-premium-accounts/store/premium-accounts-store.module';
import { TranslocoRootModule } from '@arrivage-transloco/transloco-root.module';

import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { YesNoDialogModule } from '@arrivage-components/yes-no-dialog/yes-no-dialog.module';
import packageJSON from '../../package.json';
import { AnalyticsEffects } from './_analytics/store/analytics.effets';
import { SlackEffects } from './_slack/store/slack.effects';
import { AppRoutingModule } from './app-routing.module';
import { AppUtilsModule } from './app-utils.module';
import { AppComponent } from './app.component';
import { AuthStoreModule } from './auth/store/store.module';
import { ReceivedContactRequestsStoreModule } from './contact-requests/received/store/received-contact-requests.store.module';
import { SentContactRequestsStoreModule } from './contact-requests/sent/store/sent-contact-requests.store.module';
import { ConversationStoreModule } from './conversations/store/store.module';
import { CreditAccountStoreModule } from './credit-accounts/store/credit-account-store.module';
import { CustomerDeliveryStoreModule } from './distribution/customer/store/customer-delivery-store.module';
import { VendorDeliveryStoreModule } from './distribution/vendor/store/vendor-delivery-store.module';
import { createErrorHandler } from './error-handler';
import { ExternalStoreModule } from './external/store/store.module';
import { FormatsStoreModule } from './formats/store/store.module';
import { MatDynamicPaginatorIntl } from './i18n/mat-dynmaic-paginator-intl/mat-dynamic-paginator-intl';
import { InventoryStoreModule } from './inventory/store/inventory-store.module';
import { CustomerInvoiceStoreModule } from './invoices/customer/store/customer-invoices-store.module';
import { VendorInvoiceStoreModule } from './invoices/vendor/store/vendor-invoices-store.module';
import { LanguageSelectorModule } from './language/components/selector/language-selector.module';
import { CustomLayoutGapStyleBuilder } from './layout-gap-style-builder';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { MaterialModule } from './material.module';
import { ExpansionMenuComponent } from './navigation/expansion-menu/expansion-menu.component';
import { NavigationLinkComponent } from './navigation/navigation-link/navigation-link.component';
import { PageNavigationComponent } from './navigation/pages/page-navigation.component';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { ToolbarComponent } from './navigation/toolbar/toolbar.component';
import { ViewNavigationComponent } from './navigation/view/view-navigation.component';
import { NotifDropdownModule } from './notifications/components/notif-dropdown/notif-dropdown.module';
import { NotificationStoreModule } from './notifications/store/notifications-store.module';
import { VendorOfferStoreModule } from './offers/vendor/store/vendor-offers-store.module';
import { OrganizationSettingsModule } from './organization-settings/organization-settings.module';
import { OrganizationSelectionComponent } from './organization/organization-selection/organization-selection.component';
import { OrganizationAuthorizationsStoreModule } from './organization/store/organization-authorizations-store.module';
import { OrganizationStoreModule } from './organization/store/organization-store.module';
import { PageErrorModule } from './page-error/page-error.module';
import { PriceListItemStoreModule } from './price-lists/store/price-list-item.store.module';
import { PriceListStoreModule } from './price-lists/store/price-list.store.module';
import { ProducersStoreModule } from './producers/store/store.module';
import { ProductsStoreModule } from './products/store/store.module';
import { CustomerPurchaseOrdersStoreModule } from './purchase-orders/customer/store/customer-purchase-orders-store.module';
import { VendorPurchaseOrdersStoreModule } from './purchase-orders/vendor/store/vendor-purchase-orders-store.module';
import { RelationshipStoreModule } from './relationship/common/store/relationship-store.module';
import { ScheduledOfferStoreModule } from './scheduled-offers/store/scheduled-offer.store.module';
import { SearchStoreModule } from './search/store/search-store.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { ContextEffects } from './store/context/context.effect';
import { FeedbackEffect } from './store/feedback/feedback.effects';
import { reducers } from './store/reducers';
import { RouterEffects } from './store/router/router.effects';
import { RouterStoreModule } from './store/routing/router-store.module';
import { UserSettingsModule } from './user-settings/user-settings.module';

registerLocaleData(localeFr, 'fr-CA');
registerLocaleData(localeEn, 'en-CA');

@NgModule({
  declarations: [
    AppComponent,
    OrganizationSelectionComponent,
    LoadingPageComponent,
    PageNavigationComponent,
    ToolbarComponent,
    SidenavComponent,
    NavigationLinkComponent,
    ExpansionMenuComponent,
    ViewNavigationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    MatDateFnsModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SnackbarModule,
    UploadPictureModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => {
      const analytics = getAnalytics();
      setAnalyticsCollectionEnabled(analytics, environment.production);
      return analytics;
    }),
    provideRemoteConfig(() => {
      const remoteConfig = getRemoteConfig(getApp());
      remoteConfig.settings.minimumFetchIntervalMillis =
        environment.remoteConfig.minimumFetchIntervalMillis;
      return remoteConfig;
    }),
    provideFirestore(() => {
      const f = initializeFirestore(getApp(), {
        experimentalAutoDetectLongPolling: true,
      });
      return f;
    }),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    StoreModule.forRoot(reducers, {
      runtimeChecks: environment.production
        ? {
            strictStateImmutability: false,
            strictActionImmutability: false,
          }
        : {
            strictStateImmutability: true,
            strictActionImmutability: true,
          },
    }),
    EffectsModule.forRoot([
      ContextEffects,
      FeedbackEffect,
      RouterEffects,
      AnalyticsEffects,
      SlackEffects,
    ]),
    RouterStoreModule,
    RelationshipStoreModule,
    AuthStoreModule,
    AdminStoreModule,
    ExternalStoreModule,
    OrganizationStoreModule,
    OrganizationAuthorizationsStoreModule,
    OrganizationSetupStoreModule,
    PremiumAccountStoreModule,
    NotificationStoreModule,
    VendorInvoiceStoreModule,
    CustomerInvoiceStoreModule,
    CustomerPurchaseOrdersStoreModule,
    VendorPurchaseOrdersStoreModule,
    FormatsStoreModule,
    InventoryStoreModule,
    ProductsStoreModule,
    ConversationStoreModule,
    VendorDeliveryStoreModule,
    CustomerDeliveryStoreModule,
    PickupStoreModule,
    VendorOfferStoreModule,
    PriceListStoreModule,
    PriceListItemStoreModule,
    ScheduledOfferStoreModule,
    SentContactRequestsStoreModule,
    ReceivedContactRequestsStoreModule,
    CreditAccountStoreModule,
    SearchStoreModule,
    ProducersStoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    AddressFormModule,
    OrganizationSettingsModule,
    UserSettingsModule,
    VisibleOffersStoreModule,
    AdminComponentsModule,
    LanguageSelectorModule,
    AppRoutingModule,
    DialogConfirmationModule,
    YesNoDialogModule,
    FilteredDateRangePickerDialogModule,
    UnsavedChangesDialogModule,
    LoadingDialogModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 40,
          serialize: { options: { map: true } },
          connectInZone: true,
        })
      : [],
    HttpClientModule,
    TranslocoRootModule,
    PageErrorModule,
    AppShareButtonsModule,
    NotifDropdownModule,
    OrganizationMiniModule,
    DisplayMoneyAmountPipeModule,
    UppercaseFirstPipeModule,
    AppUtilsModule,
    FullCalendarModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatDynamicPaginatorIntl },
    { provide: SETTINGS, useValue: {} },
    { provide: DEBUG_MODE, useValue: false },
    { provide: APP_VERSION, useValue: packageJSON.version },
    { provide: APP_NAME, useValue: 'arrivage-app' },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: SHARE_BUTTONS_CONFIG,
      useValue: customShareButtonsConfig,
    },
    {
      provide: LayoutGapStyleBuilder,
      useClass: CustomLayoutGapStyleBuilder,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
