import _ from 'lodash';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

import { PriceListItem, WithId } from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '../base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '../organization-entity.service';

@Injectable({
  providedIn: 'root',
})
export class PriceListItemService
  extends BaseService<PriceListItem>
  implements OrganizationEntityService<PriceListItem>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly PRICE_LIST_ITEMS_COLLECTION =
    CollectionNames.PRICE_LIST_ITEMS_COLLECTION;

  static readonly PRICE_LIST_ITEMS_EXISTS_ERROR =
    'price_list_items_exists_error';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  get(
    organizationId: string,
    recordId: string
  ): Observable<PriceListItem & WithId> {
    throw new Error('Method not implemented.');
  }

  create(
    organizationId: string,
    priceListItem: PriceListItem
  ): Promise<string> {
    return this._create(this.pathSpec(organizationId), priceListItem);
  }

  set(
    organizationId: string,
    recordId: string,
    record: PriceListItem
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    priceListItemId: string,
    priceListItem: PriceListItem
  ): Promise<void> {
    return this._update(
      this.pathSpec(organizationId),
      priceListItemId,
      priceListItem
    );
  }

  updateMany(
    organizationId: string,
    records: (Partial<PriceListItem> & WithId)[]
  ) {
    // Since we are limited with security rules and batches
    // We check before sending updates to firestore
    // See https://firebase.google.com/docs/firestore/manage-data/transactions#security_rules_limits
    const isValidBatch = _.every(records, async (record) => {
      const priceListPath = `${PriceListItemService.ORGANIZATIONS_COLLECTION}/${organizationId}/priceLists/${record.priceListId}`;
      const priceListExists = (
        await getDoc(doc(this.firestore, priceListPath))
      ).exists();

      const inventoryItemPath = `${PriceListItemService.ORGANIZATIONS_COLLECTION}/${organizationId}/inventoryItems/${record.inventoryItemId}`;
      const inventoryItemExists = (
        await getDoc(doc(this.firestore, inventoryItemPath))
      ).exists();

      return priceListExists && inventoryItemExists;
    });

    if (!isValidBatch) {
      throw Error(PriceListItemService.PRICE_LIST_ITEMS_EXISTS_ERROR);
    }

    return this._updateMany(this.pathSpec(organizationId), records);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<PriceListItem>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(organizationId: string): Observable<(PriceListItem & WithId)[]> {
    return this._list(this.pathSpec(organizationId));
  }

  remove(organizationId: string, priceListItemId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), priceListItemId);
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: PriceListItemService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: PriceListItemService.PRICE_LIST_ITEMS_COLLECTION },
    ];
  }
}
