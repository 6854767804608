import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  doc,
  Firestore,
  getDoc,
  QueryConstraint,
  where,
  writeBatch,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { reportError } from '@arrivage-sentry/report-error';
import {
  Relationship,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { BaseService, PathSpec } from '../base.service';
import { IdService } from '../id.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '../organization-entity.service';

@Injectable({
  providedIn: 'root',
})
export class RelationshipInfosService
  extends BaseService<RelationshipInfo>
  implements OrganizationEntityService<RelationshipInfo>
{
  static readonly ORGANIZATIONS_COLLECTION =
    CollectionNames.ORGANIZATION_COLLECTION;
  static readonly RELATIONSHIPS_COLLECTION =
    CollectionNames.RELATIONSHIP_COLLECTION;

  constructor(
    firestore: Firestore,
    private idService: IdService
  ) {
    super(firestore);
  }

  create(organizationId: string, record: RelationshipInfo): Promise<string> {
    throw new Error('Method not implemented.');
  }

  updateMany(
    organizationId: string,
    records: (Partial<RelationshipInfo> & WithId)[]
  ) {
    return this._updateMany(this.pathSpec(organizationId), records);
  }

  async createRelationAndRelationship(
    organizationId: string,
    relationshipInfo: RelationshipInfo,
    relationship: Relationship
  ): Promise<string> {
    try {
      const relationshipId = relationshipInfo.relationshipId;

      const relationshipInfoId: string = this.idService.createId();
      const relationshipInfoPath =
        'organizations/' + organizationId + '/relationships';

      const relationshipRef = (
        await getDoc(
          doc(
            this.firestore,
            RelationshipInfosService.RELATIONSHIPS_COLLECTION,
            relationshipId
          )
        )
      ).ref;
      const relationshipInfoRef = (
        await getDoc(
          doc(this.firestore, relationshipInfoPath, relationshipInfoId)
        )
      ).ref;
      const batch = writeBatch(this.firestore);

      batch.set(relationshipRef, relationship);
      batch.set(relationshipInfoRef, relationshipInfo);
      return batch.commit().then(() => relationshipId);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createRelationAndRelationshipWithExistingOrganizations(
    relationship: Relationship,
    relationInfo: RelationshipInfo,
    ownInfo: RelationshipInfo
  ) {
    try {
      const ownRelationshipInfoPath =
        'organizations/' + ownInfo.organizationId + '/relationships';
      const otherRelationshipInfoPath =
        'organizations/' + relationInfo.organizationId + '/relationships';

      const relationshipRef = (
        await getDoc(
          doc(this.firestore, 'relationships', relationInfo.relationshipId)
        )
      ).ref;

      const ownRelationshipInfoId = this.idService.createId();
      const otherRelationshipInfoId = this.idService.createId();

      const ownRelationshipInfoRef = (
        await getDoc(
          doc(this.firestore, ownRelationshipInfoPath, ownRelationshipInfoId)
        )
      ).ref;

      const otherRelationshipInfoRef = (
        await getDoc(
          doc(
            this.firestore,
            otherRelationshipInfoPath,
            otherRelationshipInfoId
          )
        )
      ).ref;

      const batch = writeBatch(this.firestore);

      batch.set(relationshipRef, relationship);
      batch.set(ownRelationshipInfoRef, relationInfo);
      batch.set(otherRelationshipInfoRef, ownInfo);
      return await batch.commit();
    } catch (e) {
      reportError(e);
    }
  }

  set(
    organizationId: string,
    recordId: string,
    record: RelationshipInfo
  ): Promise<void> {
    return this._set(this.pathSpec(organizationId), recordId, record);
  }

  update(
    organizationId: string,
    relationshipInfoId: string,
    relationship: Partial<RelationshipInfo>
  ): Promise<void> {
    return this._update(
      this.pathSpec(organizationId),
      relationshipInfoId,
      relationship
    );
  }

  get(
    organizationId: string,
    relationshipInfoId: string
  ): Observable<RelationshipInfo & WithId> {
    return this._get(this.pathSpec(organizationId), relationshipInfoId);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<RelationshipInfo>[]> {
    return this._connect(this.pathSpec(organizationId), (d) => d, null);
  }

  list(
    organizationId: string,
    ...filters: QueryConstraint[]
  ): Observable<(RelationshipInfo & WithId)[]> {
    return this._list(this.pathSpec(organizationId), (d) => d, ...filters);
  }

  remove(organizationId: string, relationshipInfoId: string): Promise<void> {
    return this._delete(this.pathSpec(organizationId), relationshipInfoId);
  }

  getRelationshipInfoByRelationshipId(
    relationshipId: string,
    organizationId: string
  ): Observable<RelationshipInfo> {
    return this._list(
      this.pathSpec(organizationId),
      (d) => d,
      where('relationshipId', '==', relationshipId)
    ).pipe(map((list) => (list[0] ? list[0] : undefined)));
  }

  private pathSpec(organizationId: string): PathSpec[] {
    return [
      {
        collection: RelationshipInfosService.ORGANIZATIONS_COLLECTION,
        id: organizationId,
      },
      { collection: RelationshipInfosService.RELATIONSHIPS_COLLECTION },
    ];
  }
}
