import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { reportError } from '@arrivage-sentry/report-error';
import { ImageRatio } from 'src/assets/images/images.model';

export interface PictureCropperEditorDialogData {
  imageChangedEvent: any;
  outputType: OutputFormat;
  unsupportedFormat: boolean;
  maintainAspectRatio: boolean;
  aspectRatio: ImageRatio;
}

@Component({
  selector: 'app-picture-cropper-editor-dialog',
  templateUrl: './picture-cropper-editor-dialog.component.html',
  styleUrls: ['./picture-cropper-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureCropperEditorDialogComponent {
  croppedImage: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PictureCropperEditorDialogData,
    public dialogRef: MatDialogRef<PictureCropperEditorDialogComponent>
  ) {}

  get aspectRatio(): number {
    if (
      !this.data.aspectRatio ||
      !this.data.aspectRatio.width ||
      !this.data.aspectRatio.height
    )
      return 1;

    return this.data.aspectRatio.width / this.data.aspectRatio.height;
  }

  cancel() {
    this.dialogRef.close();
  }

  upload() {
    this.dialogRef.close(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.data.unsupportedFormat = true;
    reportError(new Error('Unsupported File Format'));
  }
}
