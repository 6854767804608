import _ from 'lodash';

export namespace TimeUtils {
  export const TIME_OF_DAY_OPTIONS = _.flatMap(
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    (h) => {
      return _.map(
        ['00', '15', '30', '45'],
        (m) => `${h.toString().padStart(2, '0')}:${m}`
      );
    }
  );
}
